import React from "react"
import * as styles from "./project.module.css"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Project = ({ item }) => (
  <Link to={item.node.frontmatter.path} className={styles.project}>
    <GatsbyImage
      image={item.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
      className={styles.thumbnail}
      alt=""
    />
    <div>
      <h3>{item.node.frontmatter.title}</h3>
      <p className={styles.summary}>{item.node.frontmatter.description}</p>
    </div>
  </Link>
)

export default Project
