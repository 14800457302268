/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import email from "../images/email.svg"
import github from "../images/github.svg"
import linkedin from "../images/linkedin.svg"
import twitter from "../images/twitter.svg"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="wrapper">
          <main>{children}</main>
          <footer>
            <nav className="footer-nav">
              <a href="mailto:zhangtao2000@gmail.com" aria-label="Email">
                <img src={email} alt="" />
              </a>
              <a href="https://github.com/jimmieego" aria-label="GitHub">
                <img src={github} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/in/taozhangux"
                aria-label="LinkedIn"
              >
                <img src={linkedin} alt="" />
              </a>
              <a href="https://www.twitter.com/jimmieego" aria-label="Twitter">
                <img src={twitter} alt="" />
              </a>
            </nav>
            <p>
              Built with
              {` `}
              <a href="https://www.gatsbyjs.org">Gatsby</a> and{" "}
              <a href="https://www.netlify.com/">Netlify</a>
            </p>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
