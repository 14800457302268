import PropTypes from "prop-types"
import React from "react"
import Project from "./project"

const ProjectList = ({ items }) => (
  <div>
    {items.map(item => {
      return <Project key={item.node.id} item={item} />
    })}
  </div>
)

ProjectList.propTypes = {
  items: PropTypes.array,
}

ProjectList.defaultProps = {
  items: [],
}

export default ProjectList
