import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import * as styles from "./header.module.css"
import profileImg from "../images/Tao.png"

const Header = ({ siteTitle }) => (
  <header className={styles.header}>
    <Link className={styles.me} to="/">
      <img src={profileImg} alt="Profile" className={styles.avatar} />
      <div>
        <p className={styles.name}>{siteTitle}</p>
        <p className={styles.title}>UX Designer and Researcher</p>
      </div>
    </Link>
    <nav className={styles.headerNav}>
      <Link activeClassName={styles.active} to="/">
        Design
      </Link>
      <Link activeClassName={styles.active} to="/research">
        Research
      </Link>
      <Link activeClassName={styles.active} to="/a11y">
        Accessibility
      </Link>
      <Link activeClassName={styles.active} to="/about">
        About
      </Link>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
