import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ProjectList from "../components/project-list"

const ResearchPage = ({ data }) => (
  <Layout>
    <p className="researchIntro">
      I have extensive experience of conducting design research and usability
      tests. With my background in human factors, I can integrate data analytics
      with qualitative observations to develop deep understanding of user
      behaviors.
    </p>
    <h2>Research projects</h2>
    <ProjectList items={data.researches.edges} />
  </Layout>
)

export default ResearchPage

export const researchQuery = graphql`
  {
    researches: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "research" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            path
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            type
            description
            product
            company
          }
        }
      }
    }
  }
`
